/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div style={{overflow: `hidden`}}>
      <Helmet>
        <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="86cc7f10-328e-4625-87f0-02586a2793fa" data-blockingmode="auto" type="text/javascript"></script>
        <script
          src="https://code.jquery.com/jquery-3.3.1.slim.min.js"
          integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo"
          crossOrigin="anonymous"
        ></script>
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js"
          integrity="sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1"
          crossOrigin="anonymous"
        ></script>
        <script
          src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"
          integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM"
          crossOrigin="anonymous"
        ></script>
        <link
          href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
          rel="stylesheet"
          integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
          crossOrigin="anonymous"
        />
      </Helmet>
      <div style={{
        backgroundColor: `#000`,
        color: `#fff`,
        fontSize: `18px`,
        textAlign: `center`,
        padding: `20px 25px`,
        textTransform: `uppercase`,
        fontWeight: `bold`
      }} hidden>
        <Link to="/awards/" className="nav-item nav-link" style={{ color: `#fff` }}><span style={{color: `#30c4bf`}}>Award Ceremony Bookings</span> Must Be Made By 5pm, Monday 28th October 2024</Link>
        <a href="https://hopin.com/events/smarter-working-live-2021" style={{color: `#fff`}} hidden><span style={{color: `#30c4bf`}}>Click here to join the</span> Smarter Working Live Conference</a>
        <a href="https://events.ringcentral.com/events/smarter-working-live-2024/registration" style={{color: `#fff`}} hidden><span style={{color: `#30c4bf`}}>Click to register</span> - Award Shortlist to be announced on 18th Sept 2024 at Conference</a>
        <Link to="/enter/" className="nav-item nav-link" style={{ color: `#fff` }} hidden><span style={{color: `#30c4bf`}}>Important Update:</span> Shortlist announcement set for 18th Sept 2024 at conference</Link>
      </div>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
